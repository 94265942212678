import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  CalculationTypes,
  SeasonTypes,
  SEASON_TYPES_LIST,
} from "../../../types/enums/teamDna.enums";
import {
  DisableFiltersFields,
  DisabledFields,
  FiltersSelectedType,
  MultiValuePlayersType,
} from "../box-scores-types";
import {
  URLSearchParamsInit,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { URL } from "../../../shared/utils/route-constant";
import {
  extractValues,
  removeEmptyValues,
} from "../../../shared/utils/helper-functions";

type SearchChildren = {
  setSearchParams: (defaultInit?: URLSearchParamsInit | undefined) => void;
  filtersSelectedData: FiltersSelectedType;
  setFiltersSelectedData: Dispatch<SetStateAction<FiltersSelectedType>>;
  selectedFilters: FiltersSelectedType;
  setSelectedFilterData: Dispatch<SetStateAction<FiltersSelectedType>>;
  setPlayersInLineupList?: Dispatch<
    React.SetStateAction<
      { value: number; label: string; isDisabled: boolean }[]
    >
  >;
  setPlayersNotInLineupList?: Dispatch<
    React.SetStateAction<
      { value: number; label: string; isDisabled: boolean }[]
    >
  >;
  playersInLineupList: MultiValuePlayersType;
  playersNotInLineupList: MultiValuePlayersType;
  isFiltersVisible: boolean;
  tableLoader: boolean;
  disableFiltersFields?: DisableFiltersFields;
};

const BoxScoresFilters = ({
  setSearchParams,
  playersInLineupList,
  playersNotInLineupList,
  setPlayersInLineupList,
  setPlayersNotInLineupList,
  filtersSelectedData,
  setFiltersSelectedData,
  selectedFilters,
  setSelectedFilterData,
  isFiltersVisible = false,
  tableLoader,
  disableFiltersFields = new DisableFiltersFields(),
}: SearchChildren) => {
  const filtersData = useLoaderData() as unknown as {
    teamsList: {
      label: string;
      options: { label: string; value: string; id: number }[];
    }[];
    seasonsList: { label: string; value: string }[];
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const filterBySeasonType = (isChecked: boolean, label: SeasonTypes) => {
    if (isChecked) {
      setSelectedFilterData({
        ...selectedFilters,
        selectedSeasonTypes: [...selectedFilters.selectedSeasonTypes, label],
      });
    } else {
      setSelectedFilterData({
        ...selectedFilters,
        selectedSeasonTypes: selectedFilters.selectedSeasonTypes.filter(
          (type) => type !== label
        ),
      });
    }
  };

  const nGames = [
    { value: 1, label: "Last Game" },
    { value: 5, label: "Last 5 Games" },
    { value: 10, label: "Last 10 Games" },
    { value: undefined, label: "All Games" },
  ];

  const calculationOption = [
    { value: CalculationTypes.PER_GAME, label: "Per Game" },
    { value: CalculationTypes.PER_100, label: "Per 100" },
    { value: CalculationTypes.TOTAL, label: "Total" },
  ];

  const currPath = useLocation().pathname;

  useEffect(() => {
    if (typeof tableLoader == "boolean") {
      setIsLoading(tableLoader);
    }
  }, [tableLoader]);

  useEffect(() => {
    setSelectedFilterData(filtersSelectedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelectedData]);

  interface FilterParams {
    teamId?: string;
    season?: string;
    seasonTypes?: string[];
    noOfGames?: string;
    playersInLineup?: string[];
    playersNotInLineup?: string[];
    calType?: string;
    minMinutesplayed?: string;
  }

  function subtractObject<T extends object>(
    obj1: T,
    obj2: Partial<T>
  ): Partial<T> {
    const newObject: Partial<T> = {};
    for (const key in obj1) {
      const value1 = obj1[key];
      const value2 = obj2[key];
      if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        newObject[key] = value1;
      }
    }
    return newObject;
  }

  const saveFilters = (skipLoader = false) => {
    if (!isLoading || skipLoader) {
      const defaultFilterData = new FiltersSelectedType();

      const changedFilters = subtractObject(selectedFilters, defaultFilterData);

      const newSearchParams: FilterParams = {
        teamId: changedFilters.selectedTeam?.id.toString(),
        season: changedFilters.selectedSeasons?.value,
        seasonTypes: changedFilters.selectedSeasonTypes,
        noOfGames:
          changedFilters.selectedNoOfGames?.value === undefined
            ? undefined
            : changedFilters.selectedNoOfGames?.value.toString(),
        playersInLineup:
          (changedFilters.playersInLineup &&
            extractValues(changedFilters.playersInLineup)) ||
          [],
        playersNotInLineup:
          (changedFilters.playersNotInLineup &&
            extractValues(changedFilters.playersNotInLineup)) ||
          [],
        calType: changedFilters.selectedCalculationType?.value,
        minMinutesplayed: changedFilters.minimumMinutesPlayed,
      };

      setSearchParams(removeEmptyValues(newSearchParams));

      setFiltersSelectedData(selectedFilters);
    }
  };

  const resetFilters = () => {
    if (!isLoading) {
      setSearchParams({});
      setSelectedFilterData(new FiltersSelectedType());
      setFiltersSelectedData(new FiltersSelectedType());
    }
  };

  useEffect(() => {
    if (filtersSelectedData.selectedSeasonTypes?.length) {
      getPlayers();
    }
  }, [
    filtersSelectedData.selectedTeam,
    filtersSelectedData.selectedSeasonTypes,
  ]);

  const handleMinPlayedInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.value?.length && Number(e.target.value) > 0) {
      setSelectedFilterData({
        ...selectedFilters,
        minimumMinutesPlayed: e.target.value,
      });
    } else {
      setSelectedFilterData({
        ...selectedFilters,
        minimumMinutesPlayed: "",
      });
    }
  };

  const getPlayers = () => {};

  const getPlayersNotinLineup = (
    selectedPlayers:
      | MultiValue<{ value: number; label: string; isDisabled: boolean }>
      | undefined
  ) => {
    if (selectedPlayers && selectedPlayers.length <= 5) {
      setSelectedFilterData({
        ...selectedFilters,
        playersInLineup: selectedPlayers,
      });

      const updatedList: {
        value: number;
        label: string;
        isDisabled: boolean;
      }[] = playersNotInLineupList
        ? playersNotInLineupList.map((player) => {
            const isPlayerSelected = selectedPlayers?.some(
              (selectedPlayer) => selectedPlayer.value === player.value
            );

            if (isPlayerSelected) {
              return { ...player, isDisabled: true };
            } else if (!isPlayerSelected && player.isDisabled) {
              return { ...player, isDisabled: false };
            } else {
              return player;
            }
          })
        : [];
      if (setPlayersNotInLineupList) {
        setPlayersNotInLineupList(updatedList);
      }
    }
  };

  const getPlayersInLineup = (
    selectedPlayer: MultiValue<{
      value: number;
      label: string;
      isDisabled: boolean;
    }>
  ) => {
    setSelectedFilterData({
      ...selectedFilters,
      playersNotInLineup: selectedPlayer,
    });
    const updatedList: { value: number; label: string; isDisabled: boolean }[] =
      playersInLineupList
        ? playersInLineupList.map((player) => {
            const isPlayerSelected = selectedPlayer?.some(
              (selected) => selected.value === player.value
            );

            if (isPlayerSelected) {
              return { ...player, isDisabled: true };
            } else if (!isPlayerSelected && player.isDisabled) {
              return { ...player, isDisabled: false };
            } else {
              return player;
            }
          })
        : [];
    if (setPlayersInLineupList) {
      setPlayersInLineupList(updatedList);
    }
  };

  const isDisabledField = (
    field: keyof DisabledFields,
    currentClass: string
  ) => {
    if (disableFiltersFields.all || disableFiltersFields.fields[field]) {
      return `${currentClass} disabled`;
    }
    return currentClass;
  };

  const handleTeamChange = (
    e: SingleValue<{ value: string; label: string; id: number }>
  ) => {
    setSelectedFilterData({
      ...selectedFilters,
      selectedTeam: e,
    });
  };

  return (
    <div
      className={
        isFiltersVisible
          ? "common-card filter-card"
          : "common-card filter-card hide"
      }
    >
      <div className="flex-form filter-block filter-lineups legacy-gap--15 legacy-flex">
        <div className="legacy-flex__item">
          <div className={isDisabledField("season", "form-control")}>
            <label className="title">Season</label>
            <div className="select-box">
              <Select
                options={filtersData.seasonsList}
                closeMenuOnSelect={false}
                isClearable={false}
                value={selectedFilters.selectedSeasons}
                onChange={(
                  e: SingleValue<{
                    value: string;
                    label: string;
                  }>
                ) => {
                  setSelectedFilterData({
                    ...selectedFilters,
                    selectedSeasons: e,
                  });
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className={isDisabledField("teams", "form-control")}>
            <label className="title">Teams</label>
            <div className="select-box">
              <Select
                options={filtersData.teamsList}
                value={selectedFilters.selectedTeam}
                onChange={handleTeamChange}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Season Type</label>
            <div className="checkbox-group">
              {SEASON_TYPES_LIST.map((seasonType, index) => (
                <label key={index} className="checkbox-btn">
                  <input
                    type="checkbox"
                    checked={selectedFilters.selectedSeasonTypes.includes(
                      seasonType.value
                    )}
                    onChange={(e) =>
                      filterBySeasonType(e.target.checked, seasonType.value)
                    }
                  />
                  <span>{seasonType.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className={isDisabledField("nGames", "form-control")}>
            <label className="title">N Games</label>
            <div className="select-box">
              <Select
                options={nGames}
                value={selectedFilters.selectedNoOfGames}
                onChange={(
                  e: SingleValue<{
                    value: number | undefined;
                    label: string;
                  }>
                ) => {
                  setSelectedFilterData({
                    ...selectedFilters,
                    selectedNoOfGames: e,
                  });
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        {[`/${URL.DASHBOARD.children.BOX_SCORES.path}`].includes(currPath) && (
          <div className="legacy-flex__item">
            <div className={isDisabledField("calculation", "form-control")}>
              <label className="title">Calculation</label>
              <div className="select-box">
                <Select
                  options={calculationOption}
                  value={selectedFilters.selectedCalculationType}
                  onChange={(
                    e: SingleValue<{
                      value: CalculationTypes;
                      label: string;
                    }>
                  ) => {
                    setSelectedFilterData({
                      ...selectedFilters,
                      selectedCalculationType: e,
                    });
                  }}
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
        )}

        {[
          `/${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.LINEUPS.path}`,
        ].includes(currPath) && (
          <div className="legacy-flex__item">
            <div
              className={isDisabledField(
                "minimumMinutesPlayed",
                "form-control"
              )}
            >
              <label className="title">Minimum Minutes Played</label>
              <div className="input-field">
                <input
                  type="text"
                  className="text-field"
                  value={selectedFilters.minimumMinutesPlayed}
                  onChange={(e) => handleMinPlayedInputChange(e)}
                />
              </div>
            </div>
          </div>
        )}

        {[
          `/${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.LINEUPS.path}`,
        ].includes(currPath) && (
          <div className="legacy-flex__item">
            <div className={isDisabledField("playersInLineup", "form-control")}>
              <label className="title">Players in Lineup</label>
              <div className="select-box">
                <Select
                  isMulti
                  options={playersInLineupList ? playersInLineupList : []}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  value={selectedFilters.playersInLineup}
                  onChange={(e) => {
                    getPlayersNotinLineup(e);
                  }}
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
        )}

        {[
          `/${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.LINEUPS.path}`,
        ].includes(currPath) && (
          <div className="legacy-flex__item">
            <div
              className={isDisabledField("playersNotInLineup", "form-control")}
            >
              <label className="title">Players Not in Lineup</label>
              <div className="select-box">
                <Select
                  isMulti
                  options={playersNotInLineupList ? playersNotInLineupList : []}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  value={selectedFilters.playersNotInLineup}
                  onChange={(e) => {
                    getPlayersInLineup(e);
                  }}
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="action-filters">
        <button
          className={`btn sm-btn btn-primary text-normal btn-orange ${isLoading ? "disabled" : ""}`}
          onClick={() => {
            saveFilters();
          }}
        >
          Filter
        </button>
        <button
          className={`btn sm-btn btn-primary text-normal ${isLoading ? "disabled" : ""}`}
          onClick={resetFilters}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default BoxScoresFilters;
