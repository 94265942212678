import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";

const sizes = {
  sm: "w-12",
  md: "w-16",
  lg: "w-24",
};

const teamLogoVariants = classVariants([], {
  variants: {
    size: {
      ...sizes,
      ...responsiveVariants("sm", sizes),
      ...responsiveVariants("md", sizes),
      ...responsiveVariants("lg", sizes),
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type TeamLogoProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof teamLogoVariants> & {
    teamId: number;
  };

export function TeamLogo({ teamId, size, className, ...props }: TeamLogoProps) {
  return (
    <div className={cn(teamLogoVariants({ size }), className)} {...props}>
      <img
        src={`https://cdn.nba.com/logos/nba/${teamId}/primary/L/logo.svg`}
        className="w-full"
      />
    </div>
  );
}
