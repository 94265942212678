import { useEffect, useState } from "react";

export function useAsync<T>(fn: () => Promise<T>): {
  loading: boolean;
  response: T | null;
  error: Error | null;
} {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fn()
      .then((response) => {
        setResponse(response);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    response,
    error,
  };
}
