import { useEffect, useState } from "react";
import BoxScoresFilters from "../component/box-scores-filters";
import { DisableFiltersFields, FiltersSelectedType } from "../box-scores-types";
import { ReboundingPlayerData, ReboundingService } from "../../../swagger";
import { TableSkeleton } from "../../../components/table-skeleton/table-skeleton";
import Table from "../../../components/table/table";
import { reboundingPlayerColumns } from "../columns";
import WinLoss from "../../../components/boxscore-win-loss/boxscore-win-loss";
import { useSearchParams } from "react-router-dom";
import { SeasonTypes } from "../../../types/enums/teamDna.enums";
import { suns, teams } from "../../../shared/utils/teams";
import { Card, Text } from "@suns/design-system";

const LineUps = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
  const [filterSelectedData, setFiltersSelectedData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());
  const [selectedFilters, setSelectedFilterData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());

  const [reboundingPlayerLoader, setReboundingPlayerLoader] =
    useState<boolean>(true);
  const [reboundingPlayerData, setReboundingPlayerData] = useState<
    Array<ReboundingPlayerData>
  >([]);
  const disableFiltersFields: DisableFiltersFields = {
    ...new DisableFiltersFields(),
    all: false,
  };

  useEffect(() => {
    const incommingSearchParams = {
      teamId: searchParams.get("teamId"),
      seasons: searchParams.get("season"),
      seasonTypes: searchParams.getAll("seasonTypes") as SeasonTypes[],
      noOfGames: searchParams.get("noOfGames"),
      playersInLineup: searchParams.getAll("playersInLineup"),
      playersNotInLineup: searchParams.getAll("playersNotInLineup"),
      calType: searchParams.get("calType"),
      minMinutesplayed: searchParams.get("minMinutesplayed"),
    };

    const getTeamById = (teamId: string | undefined) => {
      if (!teamId) return null;
      const team =
        teams.find((team) => team.TEAM_ID === parseInt(teamId)) || suns;
      return {
        value: team.TEAM_ABBR,
        label: team.TEAM_FULL_NAME,
        id: team.TEAM_ID,
      };
    };
    const filterParams = {
      selectedTeam: getTeamById(
        incommingSearchParams.teamId || `${suns.TEAM_ID}`
      ),
      selectedSeasons: {
        value: incommingSearchParams.seasons || "2023-24",
        label: incommingSearchParams.seasons || "2023-24",
      },
      selectedSeasonTypes: incommingSearchParams.seasonTypes.length
        ? incommingSearchParams.seasonTypes
        : [SeasonTypes.REGULAR_SEASON],
      selectedNoOfGames: {
        value:
          incommingSearchParams.noOfGames === "undefined" ||
          incommingSearchParams.noOfGames === null
            ? undefined
            : parseInt(incommingSearchParams.noOfGames || "0"),
        label:
          incommingSearchParams.noOfGames === "undefined"
            ? "All Games"
            : incommingSearchParams.noOfGames || "All Games",
      },
      playersInLineup: [],
      playersNotInLineup: [],
      selectedCalculationType: null,
      minimumMinutesPlayed: "1",
    };

    if (searchParams.size > 0) {
      setFiltersSelectedData(filterParams);
    } else {
      setFiltersSelectedData(new FiltersSelectedType());
    }
  }, [searchParams]);

  useEffect(() => {
    if (filterSelectedData && filterSelectedData.selectedSeasonTypes?.length) {
      getReboundingPlayerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedData]);

  const getReboundingPlayerData = () => {
    setReboundingPlayerLoader(true);
    ReboundingService.getPlayerReboundingStatsData(
      filterSelectedData?.selectedSeasons?.value || "",
      filterSelectedData?.selectedSeasonTypes,
      filterSelectedData?.selectedTeam?.id || 0,
      filterSelectedData?.selectedNoOfGames?.value
    )
      .then((res) => {
        setReboundingPlayerData(res.data);
        setReboundingPlayerLoader(false);
      })
      .catch(() => {
        setReboundingPlayerLoader(false);
      });
  };
  return (
    <>
      <div className="filter-container">
        <div className="filter-head">
          <button
            className="btn sm-btn btn-primary text-normal"
            onClick={() => {
              setIsFiltersVisible(!isFiltersVisible);
            }}
          >
            {isFiltersVisible ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>
      <div className="spacer mob-hide legacy-h-10"></div>
      <BoxScoresFilters
        setSearchParams={setSearchParams}
        selectedFilters={selectedFilters}
        setSelectedFilterData={setSelectedFilterData}
        playersInLineupList={[]}
        playersNotInLineupList={[]}
        setFiltersSelectedData={setFiltersSelectedData}
        filtersSelectedData={filterSelectedData}
        isFiltersVisible={isFiltersVisible}
        tableLoader={reboundingPlayerLoader}
        disableFiltersFields={disableFiltersFields}
      ></BoxScoresFilters>
      <div className="common-card">
        <WinLoss filtersSelectedData={filterSelectedData}></WinLoss>
        <div className="table-ui">
          <div className="table-ui"></div>
        </div>
      </div>

      <Card>
        <Text heading size="lg" className="mb-2">
          PLAYER STATS
        </Text>
        <div className="table-ui text-center">
          <TableSkeleton
            columnsCount={18}
            rowsCount={6}
            outerRowsCount={1}
            fromTeamDNA={false}
            isLoading={reboundingPlayerLoader}
          >
            <Table
              columns={reboundingPlayerColumns}
              data={reboundingPlayerData}
            />
          </TableSkeleton>
        </div>
      </Card>
    </>
  );
};

export default LineUps;
