import React from "react";
import { Slot } from "@radix-ui/react-slot";

import {
  type VariantParams,
  classVariants,
  cn,
  responsiveVariants,
} from "../../utils";

export const sizeClasses = {
  "6xl": "text-6xl",
  "5xl": "text-5xl",
  "4xl": "text-4xl",
  "3xl": "text-3xl",
  "2xl": "text-2xl",
  xl: "text-xl",
  lg: "text-lg",
  md: "text-base",
  sm: "text-sm",
  xs: "text-xs",
};

const textVariants = classVariants([], {
  variants: {
    size: {
      ...sizeClasses,
      ...responsiveVariants("sm", sizeClasses),
      ...responsiveVariants("md", sizeClasses),
      ...responsiveVariants("lg", sizeClasses),
    },
    muted: {
      true: "opacity-50",
    },
    heading: {
      true: "font-bold",
      false: "",
    },
    variant: {
      destructive: "text-destructive",
      primary: "text-primary",
      secondary: "text-secondary",
    },
  },
  defaultVariants: {
    size: "md",
    muted: false,
  },
});

export interface TextProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantParams<typeof textVariants> {
  asChild?: boolean;
  as?:
    | "dt"
    | "dd"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "span"
    | "div"
    | "strong"
    | "legend";
}

export function Text({
  className,
  size,
  muted,
  as = "div",
  asChild = false,
  heading,
  variant,
  ...props
}: TextProps) {
  const Comp = asChild ? Slot : as;

  return (
    <Comp
      className={cn(textVariants({ size, muted, heading, variant }), className)}
      {...props}
    />
  );
}
