import { useEffect, useMemo, useState } from "react";
import "./lineups.scss";
import { FiltersService, LineupsRow, LineupsService } from "../../../swagger";
import { TableSkeleton } from "../../../components/table-skeleton/table-skeleton";
import Table from "../../../components/table/table";
import InfiniteScroll from "react-infinite-scroller";
import { Page } from "../../../types/pageTypes";
import { Column } from "react-table";
import BoxScoresFilters from "../component/box-scores-filters";
import { DisableFiltersFields, FiltersSelectedType } from "../box-scores-types";
import { lineupsColumns } from "../columns";
import WinLoss from "../../../components/boxscore-win-loss/boxscore-win-loss";
import { SeasonTypes } from "../../../types/enums/teamDna.enums";
import { useSearchParams } from "react-router-dom";
import { suns, teams } from "../../../shared/utils/teams";
import { convertPlayers } from "../../../shared/utils/helper-functions";
import { Card, Text } from "@suns/design-system";

const LineUps = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<Page>(new Page());
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
  const [lineupsData, setLineupsData] = useState<Array<LineupsRow>>([]);
  const [isLineupsLoading, setIsLineupsLoading] = useState<boolean>(false);
  const [playersLineup, setPlayersLineup] = useState<string>("5");
  const [lineupsLoader, setLineupsLoader] = useState<boolean>(true);
  const [initialSort, setInitialSort] = useState<{
    orderBy: string | null;
    orderDir: string;
  }>();
  const [playersInLineupList, setPlayersInLineupList] = useState<
    { value: number; label: string; isDisabled: boolean }[]
  >([]);
  const [playersNotInLineupList, setPlayersNotInLineupList] = useState<
    { value: number; label: string; isDisabled: boolean }[]
  >([]);

  const [filtersSelectedData, setFiltersSelectedData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());

  const [selectedFilters, setSelectedFilterData] =
    useState<FiltersSelectedType>(new FiltersSelectedType());

  // To disable all  fields set all to true in this object
  const disableFiltersFields: DisableFiltersFields = {
    ...new DisableFiltersFields(),
    all: false,
  };

  // update any specific field here by adding there name in this object
  disableFiltersFields.fields = {
    ...disableFiltersFields.fields,
    teams: false,
  };

  // update any specific season Type field here by adding there name in this object like mentioned below
  disableFiltersFields.fields.seasonType = {
    ...disableFiltersFields.fields.seasonType,
    all: false,
    [SeasonTypes.POST_SEASON]: false,
  };

  useEffect(() => {
    const seasonsList = selectedFilters?.selectedSeasons?.value;
    if (selectedFilters?.selectedTeam && seasonsList) {
      FiltersService.getPlayers(
        [seasonsList],
        selectedFilters?.selectedTeam.value
      ).then((res) => {
        const players: { value: number; label: string; isDisabled: boolean }[] =
          [];
        res.data.forEach((player) => {
          players.push({
            value: player.NBA_PLAYER_ID,
            label: player.LAST_NAME + ", " + player.FIRST_NAME,
            isDisabled: false,
          });
        });
        setPlayersInLineupList(players);
        setPlayersNotInLineupList(players);
      });
    }
  }, [selectedFilters]);

  const incommingSearchParams = {
    teamId: searchParams.get("teamId"),
    seasons: searchParams.get("season"),
    seasonTypes: searchParams.getAll("seasonTypes") as SeasonTypes[],
    noOfGames: searchParams.get("noOfGames"),
    playersInLineup: searchParams.getAll("playersInLineup"),
    playersNotInLineup: searchParams.getAll("playersNotInLineup"),
    calType: searchParams.get("calType"),
    minMinutesplayed: searchParams.get("minMinutesplayed"),
  };
  const getTeamById = (teamId: string | undefined) => {
    if (!teamId) return null;
    const team =
      teams.find((team) => team.TEAM_ID === parseInt(teamId)) || suns;
    return {
      value: team.TEAM_ABBR,
      label: team.TEAM_FULL_NAME,
      id: team.TEAM_ID,
    };
  };

  const memoizedPlayersInLineup = useMemo(() => {
    return convertPlayers(
      incommingSearchParams?.playersInLineup,
      playersInLineupList
    );
  }, [incommingSearchParams?.playersInLineup, playersInLineupList]);

  const memoizedPlayersNotInLineup = useMemo(() => {
    return convertPlayers(
      incommingSearchParams?.playersNotInLineup,
      playersNotInLineupList
    );
  }, [incommingSearchParams?.playersNotInLineup, playersNotInLineupList]);

  useEffect(() => {
    const filterParams = {
      selectedTeam: getTeamById(
        incommingSearchParams.teamId || `${suns.TEAM_ID}`
      ),
      selectedSeasons: {
        value: incommingSearchParams.seasons || "2023-24",
        label: incommingSearchParams.seasons || "2023-24",
      },
      selectedSeasonTypes: incommingSearchParams.seasonTypes.length
        ? incommingSearchParams.seasonTypes
        : [SeasonTypes.REGULAR_SEASON],
      selectedNoOfGames: {
        value:
          incommingSearchParams.noOfGames === "undefined" ||
          incommingSearchParams.noOfGames === null
            ? undefined
            : parseInt(incommingSearchParams.noOfGames || "0"),
        label:
          incommingSearchParams.noOfGames === "undefined"
            ? "All Games"
            : incommingSearchParams.noOfGames || "All Games",
      },
      playersInLineup: memoizedPlayersInLineup,
      playersNotInLineup: memoizedPlayersNotInLineup,
      selectedCalculationType: null,
      minimumMinutesPlayed: incommingSearchParams.minMinutesplayed || "1",
    };

    if (searchParams.size > 0) {
      setFiltersSelectedData(filterParams);
    } else {
      setFiltersSelectedData(new FiltersSelectedType());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    memoizedPlayersInLineup.length,
    memoizedPlayersNotInLineup.length,
    searchParams,
  ]);

  const resetLineupsData = () => {
    setLineupsData([]);
    setLineupsLoader(true);
    setPage(new Page());
  };

  useEffect(() => {
    resetLineupsData();
  }, [filtersSelectedData]);

  useEffect(() => {
    if (
      !lineupsData.length &&
      filtersSelectedData &&
      filtersSelectedData.selectedSeasonTypes?.length
    ) {
      getLineupsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtersSelectedData]);

  const handlePlayerLineupChange = (noOfLineups: string) => {
    if (isLineupsLoading || lineupsLoader) return;
    setPlayersLineup(noOfLineups);
    resetLineupsData();
  };

  const getLineupsData = async () => {
    try {
      setIsLineupsLoading(true);
      const playersInLineupData = filtersSelectedData?.playersInLineup?.map(
        (player) => player.value?.toString()
      );
      const playersNotInLineupsData =
        filtersSelectedData?.playersNotInLineup?.map((player) =>
          player.value?.toString()
        );
      if (filtersSelectedData) {
        LineupsService.getLineups(
          playersLineup,
          filtersSelectedData?.selectedSeasonTypes,
          filtersSelectedData?.selectedTeam?.value || "",
          filtersSelectedData?.selectedSeasons?.value,
          filtersSelectedData?.selectedNoOfGames?.value,
          filtersSelectedData?.minimumMinutesPlayed,
          playersInLineupData,
          playersNotInLineupsData,
          page?.limit,
          page?.offset,
          page?.orderBy,
          page?.orderDir
        ).then(async (response) => {
          if (response.data.rows.length) {
            setLineupsData(lineupsData.concat(response.data.rows));
            setPage({
              ...page,
              offset: page?.offset + 1,
              count: response.data.count,
            });
            setInitialSort({ orderBy: page.orderBy, orderDir: page.orderDir });
          }
          setIsLineupsLoading(false);
          setLineupsLoader(false);
        });
      }
    } catch (error) {
      setLineupsLoader(false);
    }
  };

  const sortCallback = (sortBy: { orderBy: string; orderDir: string }) => {
    setLineupsData([]);
    setLineupsLoader(true);
    const pages = {
      ...page,
      orderBy: sortBy.orderBy,
      orderDir: sortBy.orderDir,
      offset: 0,
    };
    setPage(pages);
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter-head">
          <button
            className="btn sm-btn btn-primary text-normal"
            onClick={() => {
              setIsFiltersVisible(!isFiltersVisible);
            }}
          >
            {isFiltersVisible ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>
      {/* If we will not pass disableFiltersFields then all filter will be enabled */}
      <BoxScoresFilters
        setSearchParams={setSearchParams}
        selectedFilters={selectedFilters}
        setSelectedFilterData={setSelectedFilterData}
        setPlayersInLineupList={setPlayersInLineupList}
        setPlayersNotInLineupList={setPlayersNotInLineupList}
        playersInLineupList={playersInLineupList}
        playersNotInLineupList={playersNotInLineupList}
        setFiltersSelectedData={setFiltersSelectedData}
        filtersSelectedData={filtersSelectedData}
        isFiltersVisible={isFiltersVisible}
        tableLoader={lineupsLoader || isLineupsLoading}
        disableFiltersFields={disableFiltersFields}
      ></BoxScoresFilters>
      <div className="common-card">
        <WinLoss filtersSelectedData={filtersSelectedData}></WinLoss>
        <div className="table-ui">
          <div className="table-ui"></div>
        </div>
      </div>
      <Card>
        <div className="legacy-flex__col--8">
          <Text size="lg" heading className="mb-2">
            LINEUPS
          </Text>
          <div className="tabbing-ui">
            <ul>
              <li
                className={playersLineup === "5" ? "active" : ""}
                onClick={() => handlePlayerLineupChange("5")}
              >
                <a>5-Man</a>
              </li>
              <li
                className={playersLineup === "4" ? "active" : ""}
                onClick={() =>
                  !filtersSelectedData?.playersInLineup ||
                  (filtersSelectedData?.playersInLineup?.length <= 4 &&
                    handlePlayerLineupChange("4"))
                }
              >
                <a
                  className={
                    filtersSelectedData?.playersInLineup &&
                    filtersSelectedData?.playersInLineup?.length > 4
                      ? "disabled"
                      : ""
                  }
                >
                  4-Man
                </a>
              </li>
              <li
                className={playersLineup === "3" ? "active" : ""}
                onClick={() =>
                  !filtersSelectedData?.playersInLineup ||
                  (filtersSelectedData?.playersInLineup?.length <= 3 &&
                    handlePlayerLineupChange("3"))
                }
              >
                <a
                  className={
                    filtersSelectedData?.playersInLineup &&
                    filtersSelectedData?.playersInLineup?.length > 3
                      ? "disabled"
                      : ""
                  }
                >
                  3-Man
                </a>
              </li>
              <li
                className={playersLineup === "2" ? "active" : ""}
                onClick={() =>
                  !filtersSelectedData?.playersInLineup ||
                  (filtersSelectedData?.playersInLineup?.length <= 2 &&
                    handlePlayerLineupChange("2"))
                }
              >
                <a
                  className={
                    filtersSelectedData?.playersInLineup &&
                    filtersSelectedData?.playersInLineup?.length > 2
                      ? "disabled"
                      : ""
                  }
                >
                  2-Man
                </a>
              </li>
              <li
                className={playersLineup === "center" ? "active" : ""}
                onClick={() => handlePlayerLineupChange("center")}
              >
                <a>Center</a>
              </li>
            </ul>
          </div>
          <div className="table-ui lineup-static-height">
            <InfiniteScroll
              pageStart={page?.offset}
              loadMore={() => {
                if (!lineupsData.length) {
                  return;
                }
                getLineupsData();
              }}
              hasMore={lineupsData?.length < page?.count}
              loader={
                !lineupsLoader && lineupsData?.length ? (
                  <div className="loader" key={page?.offset}></div>
                ) : (
                  <></>
                )
              }
              useWindow={false}
              key={page?.offset}
            >
              <TableSkeleton
                columnsCount={5}
                rowsCount={10}
                outerRowsCount={1}
                fromTeamDNA={false}
                isLoading={lineupsLoader}
              >
                <Table
                  columns={lineupsColumns as readonly Column<object>[]}
                  data={lineupsData}
                  isSortable={true}
                  externalSorting={true}
                  sort={sortCallback}
                  initialSort={initialSort}
                />
              </TableSkeleton>
            </InfiniteScroll>
          </div>
        </div>
      </Card>
    </>
  );
};

export default LineUps;
