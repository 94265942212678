import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { getAccount } from "@/shared/auth";

export function isBugsnagConfigured() {
  return import.meta.env.VITE_BUGSNAG_API_KEY != undefined;
}

export function startBugsnag() {
  if (!isBugsnagConfigured()) {
    return;
  }

  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_RELEASE_STAGE,
    onError: function (event) {
      const { info, isAuthenticated } = getAccount();
      if (isAuthenticated && info) {
        event.setUser(info.homeAccountId, info.username, info.name);
      } else {
        event.setUser(undefined, undefined, "Account not authenticated");
      }
    },
  });
  BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY });
}
