const ErrorFallback = () => {
  return (
    <>
      <div>
        <h1>Something went wrong!</h1>
      </div>
    </>
  );
};

export default ErrorFallback;
