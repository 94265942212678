import { MultiValue, SingleValue } from "react-select";
import {
  CalculationTypes,
  SeasonTypes,
  SeasonTypesUnion,
} from "../../types/enums/teamDna.enums";

export type SelectedTeamType = SingleValue<{
  value: string;
  label: string;
  id: number;
}>;

export type SelectedNoOfGamesType = SingleValue<{
  value: number | undefined;
  label: string;
}>;

export type SelectedCalculationType = SingleValue<{
  value: CalculationTypes;
  label: string;
}>;

export type SelectSingleValueType = SingleValue<{
  value: string;
  label: string;
}>;

export type MultiValuePlayersType = MultiValue<{
  value: number;
  label: string;
  isDisabled: boolean;
}> | null;

export class FiltersSelectedType {
  selectedSeasons: SelectSingleValueType = {
    value: "2023-24",
    label: "2023-24",
  };

  selectedNoOfGames: SelectedNoOfGamesType = {
    value: undefined,
    label: "All Games",
  };

  selectedTeam: SelectedTeamType = {
    value: "PHX",
    label: "Phoenix Suns",
    id: 1610612756,
  };

  selectedSeasonTypes: SeasonTypesUnion[] = ["Regular Season"];

  minimumMinutesPlayed = "1";

  playersInLineup: MultiValuePlayersType = [];

  playersNotInLineup: MultiValuePlayersType = [];

  selectedCalculationType: SingleValue<{
    value: CalculationTypes;
    label: string;
  }> = { value: CalculationTypes.PER_GAME, label: "Per Game" };
}

export type BoxScoreAllLoader = {
  [Loader.PLAYER_STATS]: boolean;
  [Loader.TEAM_STATS]: boolean;
};
export class DisableFiltersFields {
  all = false;

  fields: DisabledFields = {
    season: false,
    teams: false,
    seasonType: new SeasonTypeDisabledFields(),
    nGames: false,
    calculation: false,
    minimumMinutesPlayed: false,
    playersInLineup: false,
    playersNotInLineup: false,
  };
}

export type DisabledFields = {
  season: boolean;
  teams: boolean;
  seasonType: SeasonTypeDisabledFields;
  nGames: boolean;
  calculation: boolean;
  minimumMinutesPlayed: boolean;
  playersInLineup: boolean;
  playersNotInLineup: boolean;
};
export class SeasonTypeDisabledFields {
  all = false;

  [SeasonTypes.POST_SEASON] = false;

  [SeasonTypes.PRE_SEASON] = false;

  [SeasonTypes.REGULAR_SEASON] = false;
}

export enum Loader {
  PLAYER_STATS = "playerStatsLoader",
  TEAM_STATS = "teamStatsLoader",
}
