import ReactDOM from "react-dom/client";
import { Router } from "./routes";
import "./main.scss";
import "@suns/design-system/main.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import loadInterceptor from "./shared/interceptor/fetch.interceptor";
import { ToastContainer } from "react-toastify";
import { MsalProvider } from "@azure/msal-react";
import { BugsnagBoundary, startBugsnag } from "./components/bugsnag";
import { msalInstance } from "./shared/auth";

startBugsnag();
loadInterceptor();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BugsnagBoundary>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ToastContainer />
        <Router />
      </Provider>
    </MsalProvider>
  </BugsnagBoundary>
);
